
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import Store from '@/store';
import Toast from '@/toast';

import ProjectService from '@/services/project.service';

import User from '@/models/user.model';

import Layout from '@/components/Layout.vue';
import SVG from '@/components/SVG.vue';
import Loader from '@/components/Loader.vue';
import UserInput from '@/components/form/UserInput.vue';
import Table from '@/components/table/Table.vue';
import TableRow from '@/components/table/Row.vue';

export default defineComponent({
	components: {
		Layout,
		SVG,
		Loader,
		UserInput,
		Table,
		TableRow,
	},

	setup() {
		const route = useRoute();

		const user = Store.get('user');
		const allUsers = Store.get('users');
		const loading = ref(true);

		const memberLoader = ref(false);

		const projectId = Number(route.params.id);
		const project = Store.get('project');

		// load Project on mounted
		onMounted(async () => {
			if (!project.value) {
				await ProjectService.show(projectId)
					.then((response) => {
						if (response) {
							Store.set('project', response);

							users.value = response.members;
						}
					})
					.finally(() => (loading.value = false));

				return;
			}

			users.value = project.value.members;
			loading.value = false;
		});

		// Members
		const users: Ref<User[]> = ref([]);
		const newMember: Ref<number[]> = ref([]);
		const isAdding = ref(false);

		const showMembers = computed(() => {
			if (user.value.cannot('update projects') && !members.value.length) {
				return false;
			}

			return true;
		});

		const members = computed(() => {
			if (!users.value?.length) {
				return [];
			}

			return users.value.filter((user: User) => user?.member?.role == 'USER');
		});

		watch(users, (value: User[]) => {
			Store.set('project', {
				...Store.get('project'),
				users: value,
			});
		});

		watch(newMember, (value) => {
			if (!value?.length) {
				return;
			}

			const member = allUsers.value.find((user: User) => user.id === value[0]);

			if (!member) {
				Toast.error('Tað eydnaðist ikki at leggja brúkara afturat');
				return;
			}

			addMember(member);

			newMember.value = [];
			isAdding.value = false;
		});

		async function addMember(member: User) {
			// if user already exisits, don't add again
			if (members.value.find((memberToFind: User) => memberToFind.id == member.id)) {
				Toast.error('Brúkarin er longu limur');

				return;
			}

			if (!member.id) {
				return;
			}

			memberLoader.value = true;

			await ProjectService.addUser(projectId, member.id, { role: 'USER' })
				.then((response) => {
					if (response) {
						users.value = [...users.value, { ...member, member: { role: 'USER' } }];
					}
				})
				.finally(() => {
					memberLoader.value = false;
				});
		}

		function getFullName(member: User) {
			return `${member.first_name} ${member.last_name}`;
		}

		async function removeMember(memberId: number) {
			memberLoader.value = true;
			await ProjectService.removeUser(projectId, memberId)
				.then((response) => {
					if (response) {
						users.value = users.value.filter((member: User) => member.id != memberId);
					}
				})
				.finally(() => {
					memberLoader.value = false;
				});
		}

		return {
			// data
			loading,
			isAdding,
			newMember,
			project,
			memberLoader,

			// computed
			members,
			showMembers,

			// functions
			getFullName,
			removeMember,
		};
	},
});

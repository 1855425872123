import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center"
}
const _hoisted_2 = {
  key: 1,
  class: "max-w-screen-lg"
}
const _hoisted_3 = {
  key: 0,
  class: "relative"
}
const _hoisted_4 = { class: "relative group flex items-center py-3 px-4 font-bold whitespace-nowrap" }
const _hoisted_5 = { class: "ml-2" }
const _hoisted_6 = { class: "absolute right-0 top-1/2 transform -translate-y-1/2 flex bg-gray-50 opacity-0 group-hover:opacity-100" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "py-3 px-4 whitespace-nowrap" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "py-3 px-4 whitespace-nowrap text-right" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { colspan: "3" }
const _hoisted_13 = { class: "absolute -left-8 top-1/2 transform -translate-y-1/2" }
const _hoisted_14 = { class: "absolute top-0 left-0 w-full h-full bg-white-500 text-dark-500 font-bold opacity-50 z-20 flex items-center justify-center text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_SVG = _resolveComponent("SVG")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_UserInput = _resolveComponent("UserInput")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Layout = _resolveComponent("Layout")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Loader, { class: "w-8 h-8 text-blue" })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.showMembers)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_Table, {
                    title: "Limir",
                    headings: [
						`${_ctx.members.length} ${_ctx.members.length == 1 ? 'Limur' : 'Limir'}`,
						'Teldupostur',
						'Telefonnr.',
					],
                    add: _ctx.$user.cannot('update projects') || _ctx.memberLoader || _ctx.isAdding ? '' : 'Legg afturat',
                    empty: !_ctx.members?.length,
                    onAdd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAdding = true))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member) => {
                        return (_openBlock(), _createBlock(_component_TableRow, {
                          key: member.id
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("td", _hoisted_4, [
                              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getFullName(member)), 1),
                              _createElementVNode("div", _hoisted_6, [
                                (_ctx.$user.can('update projects'))
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      onClick: _withModifiers(($event: any) => (_ctx.removeMember(member.id)), ["stop"]),
                                      class: "w-5 h-5 p-[2px] text-red hover:text-red-700 cursor-pointer",
                                      title: "Strika lim"
                                    }, [
                                      _createVNode(_component_SVG, { name: "icons/trash" })
                                    ], 8, _hoisted_7))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _createElementVNode("td", _hoisted_8, [
                              _createElementVNode("a", {
                                href: `mailto:${member.email}`,
                                class: "hover:text-blue"
                              }, _toDisplayString(member.email), 9, _hoisted_9)
                            ]),
                            _createElementVNode("td", _hoisted_10, [
                              _createElementVNode("a", {
                                href: `tel:${member.phone}`,
                                class: "hover:text-blue"
                              }, _toDisplayString(member.phone), 9, _hoisted_11)
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _withDirectives(_createVNode(_component_TableRow, null, {
                        default: _withCtx(() => [
                          _createElementVNode("td", _hoisted_12, [
                            _createVNode(_component_UserInput, {
                              modelValue: _ctx.newMember,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMember) = $event)),
                              onUpdate: _cache[1] || (_cache[1] = (event) => (_ctx.newMember = event)),
                              setFocus: _ctx.isAdding,
                              borderless: true,
                              noMargin: true,
                              class: "ml-7"
                            }, null, 8, ["modelValue", "setFocus"])
                          ])
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, _ctx.$user.can('update projects') && _ctx.isAdding],
                        [_directive_click_away, () => (_ctx.isAdding = false)]
                      ])
                    ]),
                    _: 1
                  }, 8, ["headings", "add", "empty"]),
                  _withDirectives(_createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_Loader, { class: "w-4 h-4" })
                  ], 512), [
                    [_vShow, _ctx.memberLoader]
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_14, null, 512), [
                    [_vShow, _ctx.memberLoader]
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}